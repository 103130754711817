import React, { useState, useEffect } from 'react';
import { Box, Stack, Container, Typography, Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import Link from '@mui/material/Link';
import TodoForm from './components/TodoForm';
import TodoList from './components/TodoList';
import MyBar from './components/MyBar.js';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import './App.css';

import { defaultLists } from './defaultLists.js';
// import { AlignVerticalCenter } from '@mui/icons-material';
function App() {
  const [selectedList, setSelectedList] = useState('neonati');
  const [lists, setLists] = useState(() => {
    try {
      const storedLists = JSON.parse(localStorage.getItem('lists'));
      return storedLists || defaultLists;
    } catch (error) {
      console.error('Errore nel caricamento delle liste dal Local Storage:', error);
      return defaultLists;
    }
  });

  useEffect(() => {
    try {
      localStorage.setItem('lists', JSON.stringify(lists));
    } catch (error) {
      console.error('Errore nel salvataggio delle liste nel Local Storage:', error);
    }
  }, [lists]);

  const addTodo = text => {
    const newTodos = [...lists[selectedList], { text, completed: false }];
    setLists({ ...lists, [selectedList]: newTodos });
  };

  const removeTodo = index => {
    const newTodos = [...lists[selectedList]];
    newTodos.splice(index, 1);
    setLists({ ...lists, [selectedList]: newTodos });
  };

  const toggleTodo = index => {
    const newTodos = [...lists[selectedList]];
    newTodos[index].completed = !newTodos[index].completed;
    setLists({ ...lists, [selectedList]: newTodos });
  };

  const resetTodos = () => {
    setLists({ ...lists, [selectedList]: defaultLists[selectedList] });
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(lists[selectedList]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'TodoList');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(data, 'TodoList.xlsx');
  };

  return (
    <div>
      <MyBar></MyBar>
      <Container maxWidth="sm" className="main-container">
        <img src="/header3.png" alt="Header" style={{ height: 190 }} />
        <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
          <InputLabel id="select-list-label">Seleziona Lista</InputLabel>
          <Select
            labelId="select-list-label"
            value={selectedList}
            onChange={(e) => setSelectedList(e.target.value)}
            label="Seleziona Lista"
          >
            <MenuItem value="neonati">Neonati</MenuItem>
            <MenuItem value="bambini">Bambini</MenuItem>
            <MenuItem value="beauty">Beauty</MenuItem>
            <MenuItem value="farmacia">Farmacia</MenuItem>
            <MenuItem value="nondimenticare">Non dimenticare</MenuItem>
          </Select>
        </FormControl>
        <TodoList todos={lists[selectedList]} removeTodo={removeTodo} toggleTodo={toggleTodo} />
        <TodoForm addTodo={addTodo} />
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Stack direction="row" spacing={1.5}>
            <Button variant="contained" sx={{ backgroundColor: '#112B68', color: '#ffffff' }} onClick={exportToExcel} className="export-button">
              DOWNLOAD
            </Button>
            <Button variant="contained" sx={{ backgroundColor: '#DD130C', color: '#ffffff' }} onClick={resetTodos} className="reset-button">
              RIPRISTINA
            </Button>
          </Stack>
        </Box>
      </Container>
      <div className="footer">
        <Typography variant="h6" sx={{ color: '#112B68' }}><InstagramIcon sx={{ color: "#112B68" }}></InstagramIcon>   Seguici qui: <Link href="https://www.instagram.com/sailing_shibumi/" underline="none" target="_blank" rel="noopener">@sailing_shibumi</Link> e <Link href="https://www.instagram.com/ilfrangente/" underline="none" target="_blank" rel="noopener">@ilfrangente</Link>
        </Typography>
      </div>
    </div>
  );
}
export default App;
